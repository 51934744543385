<template>
  <DataTable :value="embarquesData" v-model:expandedRows="expandedRows" dataKey="contratoInternoId"
    @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" responsiveLayout="stack" selectionMode="single" id="dataTable"
    @keyup.enter="selecionar" @change="selecionar" @click="selecionar" v-model:selection="gestaoFaturamentoPedido">
    <template #header>
      <div class="flex align-items-center justify-content-between">
        <div style="width:90%"></div>
        <div class="flex align-items-center">
          <Button icon="pi pi-plus" title="Expandir todos" @click="expandAll"
            class="p-button-outlined p-button-info mr-2" />
          <Button icon="pi pi-minus" title="Recolher todos" @click="collapseAll"
            class="p-button-outlined p-button-info" />
        </div>
      </div>
    </template>

    <template #empty>
      Nenhum origem.
    </template>

    <Column :expander="true" headerStyle="width: 3rem" id="exp" />
    <Column field="contratoInternoId" header="CI"/>
    <Column field="modalidadeFrete" header="Frete" />
    <Column field="codigosEstabelecimentosOrigem" header="Origem" />
    <Column field="municipioDestino" header="Destino" />
    <Column field="cliente" header="Cliente" />
    <Column field="produtoDescricao" header="Produto" />
    <Column field="embarqueInicio" header="Início Embarque" class="centralizar-titulo-tabela">
      <template #body="{ data, field }">
        {{ formatDateCustom(data[field], "DD/MM/YYYY") }}
      </template>
    </Column>
    <Column field="embarqueFim" header="Fim Embarque" class="centralizar-titulo-tabela">
      <template #body="{ data, field }">
        {{ formatDateCustom(data[field], "DD/MM/YYYY") }}
      </template>
    </Column>
    <Column field="saldoContratoEmKg" header="Total Contrato" class="alinhar-direita-titulo-tabela">
      <template #body="{ data, field }">
        {{ formatDecimal(data[field], 2, 2) + ' Kg' }}
      </template>
    </Column>
    <Column field="solicitacaoEmbarqueEmKg" header="Solicitação Embarque" class="alinhar-direita-titulo-tabela">
      <template #body="{ data, field }">
        {{ formatDecimal(data[field], 2, 2) + ' Kg' }}
      </template>
    </Column>
    <Column field="totalFaturadoEmKg" header="Total Faturado" class="alinhar-direita-titulo-tabela">
      <template #body="{ data, field }">
        {{ formatDecimal(data[field], 2, 2) + ' Kg' }}
      </template>
    </Column>

    <template #expansion="row">
      <div class="col-12">
        <TabView v-model:activeIndex="tabViewActiveIndex">
          <TabPanel>
            <template #header>
              <i class="pi pi-mobile mr-2" style="font-size: 1rem;"></i>
              <span>Pedidos</span>
            </template>
            <div style="width:98%">
              <DataTable responsiveLayout="stack" :value="row.data.pedidos" v-model:selection="pedidoSelecionado"
                selectionMode="single" showGridlines @sort="sort($event)">
                <template #header>
                </template>
                <template #empty>
                  Nenhum Pedido Encontrado.
                </template>
                <Column field="pedidoId" header="Nr. Pedido" />
                <Column field="emissao" header="Emissão">
                  <template #body="{ data, field }">
                    {{ formatDateCustom(data[field], "DD/MM/YYYY") }}
                  </template>
                </Column>
                <Column field="codigoEstabelecimentoOrigem" header="Origem" />
                <Column field="solicitacaoEmbarqueId" header="SE" />
                <Column field="cotas" header="Cotas" />
                <Column field="terminal" header="Terminal" />
                <Column field="saldoUtilizadoEmKg" header="Total Pedido" class="alinhar-direita-titulo-tabela">
                  <template #body="{ data, field }">
                    {{ formatDecimal(data[field], 2, 2) + ' Kg' }}
                  </template>
                </Column>
                <Column field="solicitacaoEmbarqueEmKg" header="Solicitação Embarque" class="alinhar-direita-titulo-tabela">
                  <template #body="{ data, field }">
                    {{ formatDecimal(data[field], 2, 2) + ' Kg' }}
                  </template>
                </Column>
                <Column field="totalFaturamentoEmKg" header="Total Faturado" class="alinhar-direita-titulo-tabela">
                  <template #body="{ data, field }">
                    {{ formatDecimal(data[field], 2, 2) + ' Kg' }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </template>
  </DataTable>

  <Paginator :rows="filtros.size" :totalRecords="totalRegistro" :rowsPerPageOptions="[1, 5, 10]" @page="onPage" />
</template>

<script>
import { ref } from "vue";
import Formatacao from '../../../utilities/Formatacao';
import EmbarqueService from "../../../service/EmbarqueService";

export default {
  components: {},
  props: {
    filter: { type: Object, required: true },
    visivel: { type: Boolean },
    selectedItem: { type: Object },
    aba: { type: Number }
  },
  watch: {
    filter(filtro) {
      if (!!filtro) {
        this.carregarEmbarquesPorCI();
      }
    },

    selectedItem(gestaoPedido) {
      this.gestaoFaturamentoPedido = gestaoPedido;
    }
  },

  data() {
    return {
      ind: null,
      embarquesData: {},
      embarquesDataPage: {},
      gestaoFaturamentoPedido: {},
      pedidosData: {},
      pedidoSelecionado: {},
      expandedRows: [],
      filterAtivo: {},
      filtros: {},
      totalRegistro: 0,
      filtroAvancado: false,
      split: true,
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    this.abrirFiltroAvancado();
  },

  updated() {
    this.atualizaPage();
  },

  methods: {
    carregarEmbarquesPorCI() {
      if (this.aba != 2) { return; }

      this.filter.page = this.filtros.page;
      this.filter.size = this.filtros.size;
      this.filter.sort = this.filtros.sort;

      EmbarqueService.buscarPorCIComPaginacao(this.filter)
        .then(({ data }) => {
          this.embarquesData = data.content;
          this.embarquesDataPage = data;
          this.carregarFaturamentoPorEmbarque();
        })
        .catch(error => {
          this.exibirErrorToast(error);
        });
    },

    carregarFaturamentoPorEmbarque() {
      if (!this.embarquesData) return;

      this.embarquesData.forEach((embarque) => {
        EmbarqueService.buscarPedidosPorFaturamentoPedidoId(embarque.gestaoFaturamentoPedidoId)
          .then(({ data }) => {
            embarque["pedidos"] = data;
          })
          .catch(error => {
            this.exibirErrorToast(error);
          });
      });
    },

    exibirErrorToast(error) {
      const toast = {
        severity: 'error',
        summary: `Falha ao buscar a solicitação de embarque.`,
        detail: error,
        life: 7500
      }

      if (!!error && !!error.response && !!error.response.data) {
        const { message } = error.response.data;

        if (!!message) {
          toast["detail"] = message.replace("produtor", "cliente");
        }
      }

      this.$toast.add(toast);
    },

    abrirFiltroAvancado() {
      this.filtros = {};
      this.filtros["size"] = 10;
      this.filtros["page"] = 1;
      this.filtros["sort"] = 'id,asc';
    },

    atualizaPage() {
      if (!!this.embarquesDataPage.pageable && !!this.embarquesDataPage.totalPages) {
        const qtdRegistros = this.embarquesDataPage.pageable.pageSize;
        this.totalRegistro = this.embarquesDataPage.totalPages * qtdRegistros;
      }
    },

    selecionar(index) {
      this.split = false;
    },

    onPage(event) {
      this.filtros["page"] = event.page + 1;
      this.filtros["size"] = event.rows;
      this.carregarEmbarquesPorCI();
    },

    sort(event) {
      const sortField = event.sortField;
      const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
      this.filtros["sort"] = `${sortField},${sortOrder}`;
      this.carregarEmbarquesPorCI();
    },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
    },

    formatDateCustom(data, format) {
      return Formatacao.formatDateCustom(data, format);
    },

    onRowExpand(event) {
      this.gestaoFaturamentoPedido = event.data;
    },

    onRowCollapse(event) {
    },

    expandAll() {
      this.expandedRows = this.embarquesData;
    },

    collapseAll() {
      this.expandedRows = [];
    },
  }
}
</script>
<script setup>
import { ref } from "vue";

const menu = ref();
const selectedItem = ref();

const toggle = (event, data, index) => {
  menu.value.toggle(event);
  selectedItem.value = data;
};

</script>
<style scoped>
  .borda-personalizada {
    border: 1px solid #CCC;
    border-radius: 3px;
    padding: 1rem;
  }
</style>

<style>
  .centralizar-titulo-tabela {
    text-align: center !important;
  }

  .centralizar-titulo-tabela .p-column-header-content {
    display: inline;
  }

  .alinhar-direita-titulo-tabela {
      text-align: right !important;
  }

  .alinhar-direita-titulo-tabela .p-column-header-content {
      display: inline;
  }
</style>
