<template>

  <div class="grid p-fluid formgrid align-items-center">

    <div class="col-12 lg:col-2">
      <div class="field">
        <label><strong>Origem</strong></label>
        <AutoComplete
            :dropdown="true"
            class="w-full"
            field="codigoNomeFantasia"
            placeholder="Todos"
            v-model="estabelecimentoSelecionado"
            :forceSelection="true"
            :suggestions="estabelecimentosFiltro"
            @complete="carregarEstabelecimentos($event)"
            @item-select="selecionarEstabelecimento()"/>
      </div>
    </div>

    <div class="col-10 lg:col-2">
      <div class="field">
        <label><strong>Status Embarque</strong></label>
        <AutoComplete
            placeholder="Todos"
            dropdown
            v-model="statusSelecionado"
            field="descricao"
            class="w-full"
            id="1"
            :suggestions="statusFiltro"
            :forceSelection="true"
            @complete="buscarStatus"
            @item-select="selecionarStatus()"
        />
      </div>
    </div>

    <div class="col-12 lg:col-4">
      <div class="field">
        <label><strong>Cliente</strong></label>
        <EmpresaCompraVendaFiltro
            :disabled="false"
            :selecionado="clienteSelecionado"
            :campoObrigatorio="true"
            :paginacao="clienteConsulta"
            @pesquisar="pesquisarCliente"
            @selecionado="selecionarCliente"
        />
      </div>
    </div>

    <div class="col-12 lg:col-1">
      <div class="field">
        <label><strong>Pedido</strong></label>
        <InputNumber v-model="pedidoSelecionado" class="w-full" :min="0" :max="999999"/>
      </div>
    </div>

    <div class="col-12 lg:col-3">
      <div class="field">
        <label><strong>Período</strong></label>
        <div class="flex align-items-center">
          <div class="overflow-hidden">
            <Calendar
                class="w-full"
                v-model="periodoInicioSelecionado"
                autocomplete="off"
                dateFormat="dd/mm/y"
                :maxDate="periodoFimSelecionado"
                @dateSelect="selecionarPeriodoInicio()"
            />
          </div>
          <strong class="ml-2 mr-2">à</strong>
          <div class="overflow-hidden">
            <div class="p-inputgroup">
              <Calendar
                  class="w-full"
                  v-model="periodoFimSelecionado"
                  autocomplete="off"
                  dateFormat="dd/mm/y"
                  :minDate="periodoInicioSelecionado"
                  :maxDate="new Date()"
                  @dateSelect="selecionarPeriodoFim()"
              />
              <Button
                  icon="pi pi-trash"
                  style="border-radius: 0 10% 10% 0;"
                  @click="limparPeriodoModal()"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-2">
      <div class="field">
        <label><strong>Destino</strong></label>
        <AutoComplete
            field="label"
            class="w-full"
            :dropdown="true"
            @complete="buscarDestinos($event)"
            placeholder="Todos"
            v-model="destinoSelecionado"
            :suggestions="destinosFiltrados"
            @item-select="selecionarDestino"
            :forceSelection="true"
        />
      </div>
    </div>

    <div class="col-12 lg:col-2">
      <div class="field">
        <label><strong>Produto</strong></label>
        <AutoComplete
            field="nome"
            class="w-full"
            :dropdown="true"
            @complete="buscarProdutos($event)"
            placeholder="Todos"
            v-model="produtoSelecionado"
            :suggestions="produtosFiltrados"
            @item-select="selecionarProduto"
            :forceSelection="true"/>
      </div>
    </div>

    <div class="col-12 lg:col-4">
      <div class="field">
        <label><strong>Transportadora</strong></label>
        <TransportadoraZoom
            :disabled="false"
            :selecionado="transportadoraSelecionada"
            :campoObrigatorio="true"
            :paginacao="transportadoraConsulta"
            @pesquisar="pesquisarTransportadora"
            @selecionado="selecionarTransportadora"
        />
      </div>
    </div>

    <div class="col-12 lg:col-1">
      <div class="field">
        <label><strong>Nota Fiscal</strong></label>
        <InputNumber class="w-full" v-model="notaSelecionada" :min="0" :max="999999"/>
      </div>
    </div>

    <div class="col-12 lg:col-2" style="width:12%">
      <div class="field">
        <label><strong>N do CI</strong></label>
        <div class="flex align-items-center">
          <div class="overflow-hidden">
            <InputNumber class="w-full" v-model="contratoInicioSelecionado" :min="0" :max="999999"/>
          </div>
          <strong class="ml-2 mr-2">à</strong>
          <div class="overflow-hidden">
            <div class="p-inputgroup">
              <InputNumber class="w-full" v-model="contratoFimSelecionado" :min="0" :max="999999"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-2" style="width:13%">
      <div class="p-inputgroup">
        <Button
            label="Filtrar"
            icon="pi pi-filter"
            class="p-button-outlined p-button-info"
            style="width:110px"
            @click="filtrar"
        ></Button>

        <Button
            class="p-button-outlined p-button-info"
            icon="pi pi-filter-slash"
            title="Limpar Filtro"
            @click="limparFiltro"
        ></Button>
      </div>
    </div>

  </div>

</template>

<script>
import MunicipioService from '../../../service/MunicipioService.js';
import EmpresaCompraVendaFiltro from "@/components/cadastro/EmpresaCompraVendaFiltro.vue";
import Formatacao from '../../../utilities/Formatacao';
import ContratoZoom from '../../zoom/ContratoZoom.vue';
import EmbarqueTabelaOrigem from './EmbarqueTabelaOrigem.vue';
import EstabelecimentoService from "@/service/EstabelecimentoService";
import PedidoForm from "@/components/cadastro/pedido/PedidoForm.vue";
import FaturamentoForm from "@/components/cadastro/pedido/FaturamentoForm.vue";
import TransportadoraZoom from "@/components/zoom/TransportadoraZoom.vue";
import TipoProdutoService from "@/service/TipoProdutoService";
import TransportadoraService from "@/service/TransportadoraService";
import SolicitacaoEmbarqueService from "@/service/SolicitacaoEmbarqueService";

export default {
    name: "GestaoEmbarqueFilter",
    components: {TransportadoraZoom, FaturamentoForm, PedidoForm, ContratoZoom, EmbarqueTabelaOrigem, EmpresaCompraVendaFiltro },
    props: {

    },
    data() {
        return {
            statusSelecionado: null,
            statusFiltro:[],
            status: [
                { name: undefined, descricao: "Todos"},
                { name: 'A_INICIAR', descricao: "À Iniciar" },
                { name: 'INICIADO', descricao: "Iniciado" },
                { name: 'CONCLUIDO', descricao: "Concluído" }
            ],
            clienteConsulta: {},
            clienteSelecionado: {codigo:null},
            estabelecimentoSelecionado: null,
            estabelecimentosFiltro: [],
            destinosFiltrados: [],
            destinoSelecionado: null,
            produtosFiltrados: [],
            produtoSelecionado: null,
            transportadoraConsulta: {},
            transportadoraSelecionada: {codigoErp:null},
            pedidoSelecionado: null,
            periodoInicioSelecionado: null,
            periodoFimSelecionado: null,
            notaSelecionada: null,
            contratoInicioSelecionado: null,
            contratoFimSelecionado: null,

            filter: {}
        }
    },

  mounted() {
      this.atualizar();
  },

  methods: {

    carregarEstabelecimentos(event) {
      const codigoOuNomeFantasia = !event.query.trim().length ? undefined : event.query;

      EstabelecimentoService.buscarPorUsuarioAutenticadoComHierarquiaEstabelecimentoQueSejaSilo(codigoOuNomeFantasia)
          .then(({ data }) => {
            if(data) {
              const estabelecimentos = data.map(item => {
                item["codigoNomeFantasia"] = item.codigo + " - " + item.nomeFantasia;
                return item;
              });
              estabelecimentos.unshift({codigoNomeFantasia: "Todos"});
              this.estabelecimentosFiltro = [...estabelecimentos];
            }
          })
          .catch(error => {
            this.estabelecimentosFiltro = [];
          });
    },

    selecionarEstabelecimento(){
      this.filter.codigoEstabelecimento = this.estabelecimentoSelecionado.codigo;
    },

    buscarStatus(event) {
      if (!event.query.trim().length) {
        this.statusFiltro = [...this.status];
      } else {
        this.statusFiltro = this.status.filter((item) => {
          return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },

    selecionarStatus(){
      this.filter.statusEmbarque = this.statusSelecionado;
    },

    pesquisarCliente(filtros) {
      if(!!filtros && !!filtros.codigo && !filtros.size){
        SolicitacaoEmbarqueService.buscarEmpresas(filtros)
            .then(({ data }) => {
              if(data.content.length>0) {
                this.clienteSelecionado = data.content[0];
                this.filter.clienteId = data.content[0].id;
              } else {
                this.clienteSelecionado = {};
              }
            })
            .catch(error => {
              this.solicitacao.cliente = {};

              const toast = {
                severity: 'error',
                summary: `Falha ao buscar o cliente.`,
                detail: error,
                life: 7500
              }

              if(!!error && !!error.response && !!error.response.data) {
                const { message } = error.response.data;

                if(!!message) {
                  toast["detail"] = message.replace("produtor", "cliente");
                }
              }

              this.$toast.add(toast);
            });
      } else {
        SolicitacaoEmbarqueService.buscarEmpresas(filtros)
            .then(({data}) => {
              if (data) {
                this.clienteConsulta = data;
              }
            })
            .catch(error => {
              this.clienteConsulta = {}

              const toast = {
                severity: 'error',
                summary: 'Falha ao carregar os clientes.',
                detail: error,
                life: 7500
              }

              if (!!error && !!error.response && !!error.response.data) {
                const {message} = error.response.data;
                toast["detail"] = message;
              }

              this.$toast.add(toast);
            });
      }
    },

    selecionarCliente(codProdutor,produtor) {
      if(!(produtor == undefined || produtor == null)) {
        this.clienteSelecionado = produtor;
        this.filter.clienteId = produtor.id;
      } else {
        this.pesquisarCliente({codigo:codProdutor, situacao:'ATIVO'});
      }
    },

    buscarDestinos(event){
      const codigoOuDescricao = !event.query.trim().length ? 'a' : event.query;
      MunicipioService.buscarPorDescricao(codigoOuDescricao)
          .then(({ data }) => {
            if(!!data && data.length > 0) {
              const destinos = data.map(municipio => {
                municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;
                return municipio;
              });
              destinos.unshift({label: "Todos"});
              this.destinosFiltrados = [...destinos];
            } else{
              this.destinosFiltrados =[];
            }
          })
          .catch(error => {
            this.destinosFiltrados = [];
          });
    },

    selecionarDestino(){
      this.filter.destinoId = this.destinoSelecionado.id;
    },

    buscarProdutos(event){
      const dados = !event.query.trim().length ? null : event.query;
      TipoProdutoService.getAutoComplete(dados)
          .then(({ data }) => {
            let predefinidos = [];
            data.find(item => {
                predefinidos.push(item);
            });
            predefinidos.unshift({nome: "Todos"});
            this.produtosFiltrados = [...predefinidos];
          })
          .catch(error => {
            this.produtosFiltrados = [];
          });
    },

    selecionarProduto(){
      this.filter.tipoProdutoId = this.produtoSelecionado.id;
      this.filter.nomeTipoProduto = this.produtoSelecionado.nome;
    },

    pesquisarTransportadora(filtros) {
      //filtros={size:10,pages:0}

      TransportadoraService.buscarComPaginacao(filtros)
          .then(({ data }) => {
            if(data) {
              this.transportadoraConsulta = data;
            }
          })
          .catch(errorco => {
            this.transportadoraConsulta = {}

            const toast = {
              severity: 'error',
              summary: 'Falha ao carregar a transportadoras.',
              detail: error,
              life: 7500
            }

            if(!!error && !!error.response && !!error.response.data) {
              const { message } = error.response.data;
              toast["detail"] = message;
            }

            this.$toast.add(toast);
          });
    },

    selecionarTransportadora(codTransportadora,transportadora) {
      if(!(codTransportadora == undefined || codTransportadora == null)) {
        const codigo = Number(codTransportadora);

        TransportadoraService.buscarPorCodigo(codigo)
            .then(({data}) => {
              if (!!data) {
                this.transportadoraSelecionada = data.transportadora;
                this.filter.transportadoraId = data.transportadora.id;
              } else {
                this.transportadoraSelecionada = null;
                this.filter.transportadoraId = null;
              }
            })
            .catch(error => {
              this.transportadoraSelecionada = {};
              this.filter.transportadoraId = null;
              const toast = {
                severity: 'error',
                summary: `Falha ao buscar o cliente.`,
                detail: error,
                life: 7500
              }

              if (!!error && !!error.response && !!error.response.data) {
                const {message} = error.response.data;

                if (!!message) {
                  toast["detail"] = message.replace("produtor", "cliente");
                }
              }

              //this.$toast.add(toast);
            });
      }
    },

    selecionarPedido(){
      if (!!this.pedidoSelecionado) this.filter.pedidoId = this.pedidoSelecionado;
    },

    selecionarPeriodoInicio(){
      this.filter.inicioEmbarque = this.periodoInicioSelecionado;
    },

    selecionarPeriodoFim(){
      this.filter.fimEmbarque = this.periodoFimSelecionado;
    },

    selecionarNota(){
      if (!!this.notaSelecionada) this.filter.numeroNotaFiscal = this.notaSelecionada;
    },

    selecionarContratoInicio(){
      if (!!this.contratoInicioSelecionado) this.filter.ciInicial = this.contratoInicioSelecionado;
    },

    selecionarContratoFim(){
      if (!!this.contratoFimSelecionado) this.filter.ciFinal = this.contratoFimSelecionado;
    },

    filtrar(){
      this.selecionarPedido();
      this.selecionarNota();
      this.selecionarContratoInicio();
      this.selecionarContratoFim();
      this.atualizar(false);
    },

    limparFiltro(){
      this.statusSelecionado= null;
      this.clienteSelecionado= 0;
      this.estabelecimentoSelecionado= null;
      this.destinoSelecionado= null;
      this.produtoSelecionado= null;
      this.transportadoraSelecionada= 0;
      this.pedidoSelecionado= null;
      this.periodoInicioSelecionado= null;
      this.periodoFimSelecionado= null;
      this.notaSelecionada= null;
      this.contratoInicioSelecionado= null;
      this.contratoFimSelecionado= null;
      
      this.filter = {};
      this.atualizar(true);
    },

    limparPeriodoModal(){
      this.periodoInicioSelecionado= null;
      this.periodoFimSelecionado= null;
    },

    atualizar(limpaAtivo){
      const filtro = {};
      filtro["codigoEstabelecimento"] = !this.filter.codigoEstabelecimento ? null : this.filter.codigoEstabelecimento;
      filtro["statusEmbarque"] = !this.filter.statusEmbarque ? null : this.filter.statusEmbarque.name;
      filtro["clienteId"] = !this.filter.clienteId ? null : this.filter.clienteId;
      filtro["nomeCliente"] = !this.filter.nomeCliente ? null : this.filter.nomeCliente;
      filtro["pedidoId"] = !this.filter.pedidoId ? null : this.filter.pedidoId;
      filtro["inicioEmbarque"] = !this.filter.inicioEmbarque ? null : Formatacao.formatDateCustom(this.filter.inicioEmbarque, "YYYY-MM-DD");
      filtro["fimEmbarque"] = !this.filter.fimEmbarque ? null : Formatacao.formatDateCustom(this.filter.fimEmbarque, "YYYY-MM-DD");
      filtro["destinoId"] = !this.filter.destinoId ? null : this.filter.destinoId;
      filtro["tipoProdutoId"] = !this.filter.tipoProdutoId ? null : this.filter.tipoProdutoId;
      filtro["nomeTipoProduto"] = !this.filter.tipoProdutoId ? null : this.filter.nomeTipoProduto;
      filtro["transportadoraId"] = !this.filter.transportadoraId ? null : this.filter.transportadoraId;
      filtro["numeroNotaFiscal"] = !this.filter.numeroNotaFiscal ? null : this.filter.numeroNotaFiscal;
      filtro["ciInicial"] = !this.filter.ciInicial ? null : this.filter.ciInicial;
      filtro["ciFinal"] = !this.filter.ciFinal ? null : this.filter.ciFinal;
      if (limpaAtivo) filtro["filterAtivo"] = null;

      this.$emit("atualizar", filtro);
    },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        }
    }

}
</script>

<style scoped>

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
    }
</style>