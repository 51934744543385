<template>
    <div class="card">
        <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="w-full align-items-center">            
                <h3>
                    <img src="layout/images/truck.png" alt="" style="font-size: 1.4rem">
                  Gestão de Embarque
                </h3>
                <hr />
            </div>

          <GestaoEmbarqueFilter :filter="filtro" @atualizar="atualizaFiltro" />

          <TabView v-model:activeIndex="tabViewActiveIndex" class="mt-3" @tabChange="reloadAba(tabViewActiveIndex)">
            <TabPanel>
              <template #header>
                <i class="pi pi-desktop mr-2" style="font-size: 1rem;"></i>
                <span>Solicitação Embarque</span>
              </template>
              <EmbarqueTabelaOrigem :filter="filtro" :visivel="true" :aba="tabViewActiveIndex"/>
            </TabPanel>

            <TabPanel>
              <template #header>
                <i class="pi pi-dollar mr-2" style="font-size: 1rem;"></i>
                <span>Faturamentos</span>
              </template>
              <EmbarqueFaturamento :filter="filtro" :visivel="true" :aba="tabViewActiveIndex"/>
            </TabPanel>

            <TabPanel>
              <template #header>
                <i class="pi pi-list mr-2" style="font-size: 1rem;"></i>
                <span>Consulta por CI</span>
              </template>
              <EmbarqueConsultaPorCI :filter="filtro" :visivel="true" :aba="tabViewActiveIndex"/>
            </TabPanel>

          </TabView>

        </div>
    </div>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import GestaoEmbarqueFilter from "@/components/cadastro/embarque/GestaoEmbarqueFilter.vue";
import EmbarqueTabelaOrigem from "@/components/cadastro/embarque/EmbarqueTabelaOrigem.vue";
import EmbarqueFaturamento from "@/components/cadastro/embarque/EmbarqueFaturamento.vue";
import EmbarqueConsultaPorCI from './EmbarqueConsultaPorCI.vue';

export default {
    name: "GestaoEmbarqueForm",
    components: {EmbarqueTabelaOrigem, EmbarqueFaturamento, GestaoEmbarqueFilter, EmbarqueConsultaPorCI },
    props: {
      filtro:{
        type: Object,
        required: true
      }
    },
    data() {
        return {
          filtro: {},
          tabViewActiveIndex: 0,
          reload: 0
        }
    },

  methods: {

    atualizaFiltro(filtroAtual){
        this.filtro = Object.assign({}, filtroAtual);
    },

    voltar() {
        this.$router.push(`/embarques`);
    },

    reloadAba(index){
        this.atualizaFiltro(this.filtro);
      },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
        return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
    },

    formatDateCustom(data, format){
        return Formatacao.formatDateCustom(data, format);
    }
  }
}
</script>

<style scoped>

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
    }
</style>