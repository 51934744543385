import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class EmbarqueService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/embarque`, dados, config);
    }

    buscarPorId(idEmbarque) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/embarque/${ idEmbarque }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/embarque/buscar-com-paginacao`, { params });
    }

    buscarPorCIComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/embarque-por-contrato-interno/buscar-com-paginacao`, { params });
    }

    buscarPedidosPorFaturamentoPedidoId(gestaoFaturamentoPedidoId) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/embarque-por-contrato-interno/pedido/buscar-por/gestao-faturamento-pedido/${ gestaoFaturamentoPedidoId }`);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/embarque/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/embarque/ativar/${ id }`);
    }
}

export default new EmbarqueService();