<template>
  <Dialog v-model:visible="visivel" :style="{width: '90%', maxWidth: '1400px'}" :modal="true" :closable="false" @show="init()" >
    <template #header>
      <div class="flex align-items-center mt-2">
        <i class="pi pi-file mr-2" style="font-size: 1.4rem;"></i>
        <h4 style="margin:0;"> Solicitação Embarque Pendentes </h4>
      </div>
    </template>

    <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

    <br>
    
    <div class="grid p-fluid formgrid align-items-center">

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Estabelecimento<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              :dropdown="true"
              class="w-full"
              field="codigoNomeFantasia"
              placeholder="Selecione..."
              :forceSelection="true"
              v-model="estabelecimentoSelecionado"
              :suggestions="estabelecimentosFiltro"
              @complete="carregarEstabelecimentos($event)"
              />
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="field">
          <label><strong>Cliente<span style="color:red;">&nbsp;*</span></strong></label>
          <EmpresaCompraVendaFiltro
              :selecionado="clienteSelecionado"
              :campoObrigatorio="true"
              :paginacao="clienteConsulta"
              @pesquisar="pesquisarCliente"
              @selecionado="selecionarCliente"
              onblur="mostraMensagem()"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>CNPJ</strong></label>
          <InputText disabled class="w-full" v-model="cnpjSelecionado"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Produto<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              field="nome"
              class="w-full"
              :dropdown="true"
              @complete="buscarProdutos($event)"
              placeholder="Selecione..."
              v-model="produtoSelecionado"
              :suggestions="produtosFiltrados"
              :forceSelection="true"
          />
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="field">
          <label><strong>Destino<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              field="label"
              class="w-full"
              :dropdown="true"
              @complete="buscarDestinos($event)"
              placeholder="Selecione..."
              v-model="destinoSelecionado"
              :suggestions="destinosFiltrados"
              @item-select="terminalSelecionado = null"
              :forceSelection="true"
          />
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="field">
          <label><strong>Terminal</strong></label>
          <AutoComplete
              field="label"
              class="w-full"
              :dropdown="true"
              @complete="buscarTerminais($event)"
              placeholder="Selecione..."
              v-model="terminalSelecionado"
              :suggestions="terminaisFiltrados"
              :forceSelection="true"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2" style="width:13%">
        <div class="p-inputgroup">
          <Button
              label="Filtrar"
              icon="pi pi-filter"
              class="p-button-outlined p-button-info"
              style="width:110px"
              @click="filtrar"
          ></Button>

          <Button
              class="p-button-outlined p-button-info"
              icon="pi pi-filter-slash"
              title="Limpar Filtro"
              @click="limparFiltro"
          ></Button>
        </div>
      </div>

    </div>

    <DataTable :value="solicitacoesEmbarque.content"
              v-model:selection="solicitacaoEmbarque"
              showGridlines
              dataKey="id"
              responsiveLayout="stack"
              selectionMode="single">

          <template #empty>
              Nenhum pedido encontrado.
          </template>

          <Column field="estabelecimento.codigo" header="Estab." style="width: 5rem">
            <template #body="{ data }">
              <span :title="data.estabelecimento.nome">{{ data.estabelecimento.codigo }}</span>
            </template>
          </Column>

          <Column field="cliente.codigo" header="Cliente" style="width: 20rem">
            <template #body="{ data }">
              {{ data.cliente.codigo }} - {{ data.cliente.nome }}
            </template>
          </Column>

          <Column field="tipoProduto.nome" header="Produto">
            <template #body="{ data }">
              {{data.tipoProduto.nome}}
            </template>
          </Column>

          <Column field="destino.nome" header="Destino">
            <template #body="{ data }">
              {{data.destino.nome}}/{{data.destino.uf}}
            </template>
          </Column>

          <Column field="terminal.nomeFantasia" header="Terminal"/>

          <Column field="id" header="SE" class="centralizar-titulo-tabela" style="width: 5rem;"/>

          <Column field="inicioEmbarque" header="Inicio Embarque" class="centralizar-titulo-tabela">
              <template #body="{ data , field }">
                {{ formatDateCustom(data[field], "DD/MM/YYYY") }}
              </template>
          </Column>

          <Column field="cota" header="Cotas" class="centralizar-titulo-tabela" style="width: 5rem;">
              <template #body="{ data }">
                <strong :class="'product-badge status-' + corStatusCota(data)">
                  {{data.cotaRealizada}}/{{data.cotaLiberada}}
                </strong>
              </template>
          </Column>

          <Column field="saldoEmbarque" header="Saldo Embarque" class="alinhar-direita-titulo-tabela">
            <template #body="{ data , field }">
              {{ formatDecimal(data[field], 2, 2) +' kg' }}
            </template>
          </Column>

          <Column style="width: 7rem;">
            <template #body="{ data }">
              <Button
                  icon="pi pi-check"
                  label="Selecionar"
                  class="p-button p-button-info mt-2 mb-2"
                  @click="selecionarSEPendente(data)"
              ></Button> 
            </template>
          </Column>

          <template #footer>               
            <Paginator
              v-model:first="firstRow"
              :rows="filtro.size"
              :totalRecords="totalRegistro"
              :rowsPerPageOptions="[5, 10, 15]"
              @page="onPage"/>
          </template>

      </DataTable>

    <template #footer>
        <div class="flex justify-content-center">
            <Button
                icon="pi pi-times"
                label="Cancelar"
                class="p-button-danger ml-2"
                @click="fechar(false)"
            ></Button>
        </div>
    </template>
</Dialog>
</template>

<script>
import EmpresaCompraVendaFiltro from "@/components/cadastro/EmpresaCompraVendaFiltro.vue";
import TerminalDesembarqueService from '../../../service/TerminaisDesembarqueService.js';
import SolicitacaoEmbarqueService from "../../../service/SolicitacaoEmbarqueService";
import EstabelecimentoService from "@/service/EstabelecimentoService";
import MunicipioService from '../../../service/MunicipioService.js';
import TipoProdutoService from "@/service/TipoProdutoService";
import Formatacao from '../../../utilities/Formatacao';
import StorageService from "@/service/StorageService";

export default {
  name: "GestaoEmbarqueSEPendente",
  emits: ["fechar", "selecionarSEPendente"],
  components: { EmpresaCompraVendaFiltro },

  props: {
    visivel: { type: Boolean, required: true },
    filtro:{
      type: Object,
      required: true
    }
  },
  
  data() {
      return {    
          filtro: {},  
          totalRegistro: 0, 
          firstRow: 0,     
          clienteConsulta: {},
          clienteSelecionado:{},
          solicitacaoEmbarque: {},
          solicitacoesEmbarque: {},
          estabelecimentoSelecionado: null,
          estabelecimentosFiltro: [],
          destinosFiltrados: [],
          destinoSelecionado: null,
          produtosFiltrados: [],
          produtoSelecionado: null,
          terminalSelecionado: null,
          terminaisFiltrados: [],
          cnpjSelecionado: null,
          mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
      }
  },

  methods: {
    init(){
      this.filtro["size"] = 10;
      this.filtro["page"] = 1;
      this.filtro["sort"] = 'codigoEstabelecimento,cliente.nome,asc';
      this.filtrar();
    },

    limparFiltro(){
      this.estabelecimentoSelecionado = null;
      this.clienteSelecionado = {};
      this.destinoSelecionado=null;
      this.produtoSelecionado=null;
      this.terminalSelecionado=null;
      this.cnpjSelecionado=null;

      this.filtro = {};
      this.filtro["size"] = 10;
      this.filtro["page"] = 1;
      this.filtro["comSaldoFaturamento"] = true;
      this.filtro["sort"] = "codigoEstabelecimento,cliente.nome,destino.nome,nomeTipoProduto,inicioEmbarque,asc";
      this.filtrar();
    },

    atualizarFiltro(){
      this.filtro["codigoEstabelecimento"] = this.estabelecimentoSelecionado == null ? null : this.estabelecimentoSelecionado.codigo;
      this.filtro["clienteId"] = this.clienteSelecionado.id == null || this.clienteSelecionado.id == undefined ? null : this.clienteSelecionado.id;
      this.filtro["destinoId"] = this.destinoSelecionado == null ? null : this.destinoSelecionado.id;
      this.filtro["tipoProdutoId"] = this.produtoSelecionado == null ? null : this.produtoSelecionado.id;
      this.filtro["terminalId"] = this.terminalSelecionado == null ? null : this.terminalSelecionado.id;
      this.filtro["comSaldoFaturamento"] = true;
      this.filtro["page"] = 1;
      this.filtro.sort = "codigoEstabelecimento,cliente.nome,destino.nome,nomeTipoProduto,inicioEmbarque,asc";
    },

    fechar(sv){
      this.$emit("fechar",sv);
    },

    selecionarSEPendente(data){
      this.$emit("selecionarSEPendente", data);
    },

    carregarEstabelecimentos(event) {
      const codigoOuNomeFantasia = !event || !event.query.trim().length ? undefined : event.query;
      EstabelecimentoService.buscarPorUsuarioAutenticadoComHierarquiaEstabelecimentoQueSejaSilo(codigoOuNomeFantasia)
          .then(({ data }) => {
            if(data) {
              this.estabelecimentosFiltro = data.map(item => {
                item["codigoNomeFantasia"] = item.codigo + " - " + item.nomeFantasia;
                item["nome"] = item.nomeFantasia;
                return item;
              });
            }
          })
          .catch(error => {
            this.estabelecimentosFiltro = [];
          });
    },

    pesquisarCliente(filtros) {
      this.mensagem.ativo = false;
      if(!!filtros && !!filtros.codigo && !filtros.size){
        SolicitacaoEmbarqueService.buscarEmpresas(filtros)
            .then(({ data }) => {
              if(data.content.length>0) {
                this.clienteSelecionado = data.content[0];
              } else {
                this.clienteSelecionado = {};
                this.mostraMensagem("Cliente Inexistente.");
              }
            })
            .catch(error => {
              this.clienteSelecionado = {};

              const toast = {
                severity: 'error',
                summary: `Falha ao buscar o cliente.`,
                detail: error,
                life: 7500
              }

              if(!!error && !!error.response && !!error.response.data) {
                const { message } = error.response.data;

                if(!!message) {
                  toast["detail"] = message.replace("produtor", "cliente");
                }
              }

              this.$toast.add(toast);
            });
      } else {
        SolicitacaoEmbarqueService.buscarEmpresas(filtros)
            .then(({data}) => {
              if (data) {
                this.clienteConsulta = data;
              }
            })
            .catch(error => {
              this.clienteConsulta = {}

              const toast = {
                severity: 'error',
                summary: 'Falha ao carregar os clientes.',
                detail: error,
                life: 7500
              }

              if (!!error && !!error.response && !!error.response.data) {
                const {message} = error.response.data;
                toast["detail"] = message;
              }

              this.$toast.add(toast);
            });
      }
    },

    selecionarCliente(codProdutor,produtor) {
      if(!(produtor == undefined || produtor == null)) {
        this.clienteSelecionado = produtor;
        this.cnpjSelecionado = produtor.cnpj;
      } else {
        this.pesquisarCliente({codigo:codProdutor, situacao:'ATIVO'});
      }
    },

    buscarDestinos(event){
      const codigoOuDescricao = event==undefined || !event.query.trim().length ? 'a' : event.query;
      MunicipioService.buscarPorDescricao(codigoOuDescricao)
          .then(({ data }) => {
            if(!!data && data.length > 0) {
              this.destinosFiltrados = data.map(municipio => {
                municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;               

                return municipio;
              });
            } else{
              this.destinosFiltrados =[];
            }
          })
          .catch(error => {
            this.destinosFiltrados = [];
          });
    },

    buscarProdutos(event){
      const dados = event==undefined || !event.query.trim().length ? null : event.query;
      TipoProdutoService.getAutoComplete(dados)
          .then(({ data }) => {
            let predefinidos = [];
            data.find(item => {
                predefinidos.push(item);
            });
            this.produtosFiltrados = predefinidos;
          })
          .catch(error => {
            this.produtosFiltrados = [];
          });
    },

    buscarTerminais(event) {
        const params = {};
        if(!!this.destinoSelecionado) params["municipioId"] = this.destinoSelecionado.id;
        if(event != undefined && event != null && !!event.query.trim().length) params["cnpjOuNomeFantasia"] = event.query;

        TerminalDesembarqueService.getPorDescricao(params)
            .then(({ data }) => {
              
              this.terminaisFiltrados = data.map(terminal => {
                terminal["label"] = `${ terminal.nomeFantasia } - ${ terminal.cnpj }`;
                return terminal;
              });
              
            })
            .catch(error => {
              this.terminaisFiltrados = [];
            });

    },

    filtrar(){      
      this.firstRow = 0;
      this.atualizarFiltro();
      this.carregarDados();
    },

    carregarDados(){
      
      SolicitacaoEmbarqueService.buscarComPaginacao(this.filtro)
        .then(({data}) => {
          if (!!data) {
              const dados = data.content.map(item => {
                  item.valorFreteFormatado = this.formata(item.valorFrete);  
                  return item;
              });
              this.solicitacoesEmbarque = data;
              this.solicitacoesEmbarque.content = dados;
              this.totalRegistro = data.totalElements;
              this.filtro["size"] = data.pageable.pageSize; 
              
          }
        })
        .catch(error => {
          const toast = {
            severity: 'error',
            summary: `Falha ao buscar a solicitação de embarque.`,
            detail: error,
            life: 7500
          }

          if (!!error && !!error.response && !!error.response.data) {
            const {message} = error.response.data;

            if (!!message) {
              toast["detail"] = message.replace("produtor", "cliente");
            }
          }

          this.$toast.add(toast);
        }
      );

    },

    onPage(event) {
      this.firstRow = event.rows * event.page;
      this.filtro["page"] = event.page + 1;
      this.filtro["size"] = event.rows;
      this.carregarDados();
    },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
    },

    formatDateCustom(data, format){
      return Formatacao.formatDateCustom(data, format);
    },

    formata(valor){
      return "R$ " + Formatacao.formatDecimal(valor,2,2) + " / TON";
    },

    corStatusCota(data){
        if(data.cotaRealizada == 0 && data.cotaRealizada < data.cotaLiberada){
            return 'instock';
        }else
        if(data.cotaRealizada > 0 && data.cotaRealizada < data.cotaLiberada){
          return 'lowstock';
        }else
        if(data.cotaRealizada >= data.cotaLiberada){                
            return 'outofstock';
        }
    }

  }

}
</script>

<style>    
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>


<style scoped>
    .borda-personalizada {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1rem;
    }
</style>

<style scoped lang="scss">
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    @import '../../../assets/demo/styles/badges.scss';
    .fontUpper{
        text-transform: uppercase;
    }

    .width10rem {
      width: 10rem;
    }
</style>
