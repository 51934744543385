<template>
    <DataTable :value="faturamentos.content"
            dataKey="id"
            responsiveLayout="stack"
            selectionMode="single"
            id="dataTable"
            @keyup.enter="selecionar"
            @change="selecionar"
            @click="selecionar"
            v-model:selection="faturamento">
        <template #header>
            <div class="flex align-items-center justify-content-between">
                <div style="width:90%">
                  Notas Fiscais Emitidas
                </div>

                <div class="flex align-items-center">
                    <Button icon="pi pi-plus" title="Incluir" label="Incluir" @click="modalSolicitacaoEmbarquePendente" class="p-button-outlined p-button-info mr-2" />
                </div>
            </div>
        </template>

        <template #empty>
            Nenhum origem.
        </template>

        <Column field="dataCadastro" header="Emissão" style="width: 5rem;" >
          <template #body="{ data , field }">
            {{ formatDateCustom(data[field],"DD/MM/YY") }}
          </template>
        </Column>
        <Column field="dataNrDocPes" header="Ticket" class="centralizar-titulo-tabela" style="width: 5rem;">
            <template #body="{ data , field }">
              {{ formatDateCustom(data[field],"DD/MM/YY") }}
            </template>
        </Column>
        <Column field="pedidoId" header="Pedido" style="width: 5rem;"/>
        <Column field="nrNotaFiscal" header="NF" style="width: 5rem;"/>
        <Column field="codigoEstabelecimento" header="Estab." class="centralizar-titulo-tabela" style="width: 5rem;"/>
        <Column field="cliente" header="Cliente" class="centralizar-titulo-tabela" style="width: 5rem;">
          <template #body="{ data }">
             <span :title="data.cliente.nome"> {{ data.cliente.codigo }} </span>
            </template>
        </Column>
        <Column field="placa" header="Placa" class="centralizar-titulo-tabela" style="width: 5rem;">
          <template #body="{data}">
            {{data.placa}}{{data.ufPlaca!=null?'/'+data.ufPlaca:""}}
          </template>
        </Column>
        <Column field="nomeTransportadora" header="Transp." class="centralizar-titulo-tabela" style="width: 5rem;">
            <template #body="{ data }">
             <span :title="data.nomeTransportadora"> {{ data.codigoErpTransportadora }} </span>
            </template>
        </Column>
        <Column field="destino" header="Destino">
          <template #body="{ data }">
            {{ data.destino.nome }}/{{ data.destino.uf }}
          </template>
        </Column>
        <Column field="nomeTipoProduto" header="Produto"/>
        <Column field="codigoItemNota" header="Item"/>
        <Column field="quantidade" header="Quantidade" class="alinhar-direita-titulo-tabela">
          <template #body="{ data , field }">
            {{ formatDecimal(data[field],2,2) +' kg' }}
          </template>
        </Column>
        <Column field="valorUnitario" header="Vlr. Unit." class="alinhar-direita-titulo-tabela">
          <template #body="{ data , field }">
            {{'R$ '+ formatDecimal(data[field],4,4) }}
          </template>
        </Column>
        <Column field="valorTotal" header="Vrl. Total" class="alinhar-direita-titulo-tabela">
          <template #body="{ data , field }">
            {{'R$ '+  formatDecimal(data[field],2,2) }}
          </template>
        </Column>

        <Column header="Ações" style="width:2rem;" >
          <template #body="{ data, index }">
            <Button type="button" class="p-button-rounded p-button-info mr-2 mb-2" icon="pi pi-ellipsis-v" @click="toggle($event,data,index)" aria-haspopup="true" aria-controls="overlay_menu" />
            <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
          </template>
        </Column>

    </DataTable>

    <Dialog v-model:visible="excluirFaturamentoDialog" :style="{ width: '300px' }" header="Alerta" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>Tem certeza que deseja <strong>Excluir</strong> a solicitação de embarque {{ ind }}?</span>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-text" @click="excluirFaturamentoDialog = false" />
        <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="excluirfaturamento(ind)" />
      </template>
    </Dialog>

  <Paginator
      :rows="filtros.size"
      :totalRecords="totalRegistro"
      :rowsPerPageOptions="[1, 5, 10]"
      @page="onPage"/>

  <ModalFaturamentoEmbarque
      :faturamento="faturamentoDto"
      :visivel="modalFaturamentoDialog"
      @fechar="fechar"
  />

  <ModalSolicitacaoEmbarquePendente
      :visivel="modalSolicitacaoEmbarquePendenteDialog"
      @selecionarSEPendente="selecionarSolicitacaoEmbarquePendente"
      @fechar="fechar"      
      :filtro="filter"/>

</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import FaturamentoService from "@/service/pedido/FaturamentoService";
import ModalFaturamentoEmbarque from "@/components/cadastro/embarque/ModalFaturamentoEmbarque.vue";
import ModalSolicitacaoEmbarquePendente from "@/components/cadastro/embarque/ModalSolicitacaoEmbarquePendente.vue";
import {ref} from "vue";

export default {
  components: {ModalFaturamentoEmbarque, ModalSolicitacaoEmbarquePendente},
  props: {
    filter: { type: Object, required: true },
    visivel: { type: Boolean },
    selectedItem: { type: Object },
    aba: { type: Number }
  },
  watch: {
    filter(filtro) {
      if(!!filtro) {
        this.buscaFaturamentos();
      }
    },
    selectedItem(t){
      this.faturamento = t;
    }

  },

  data() {
    return {
        modalSolicitacaoEmbarquePendenteDialog: false,
        modalFaturamentoDialog: false,
        excluirFaturamentoDialog: false,
        ind: null,
        faturamentos: {},
        faturamento: {},
        filterAtivo: {},
        filtros: {},
        totalRegistro: 0,
        filtroAvancado: false,
        split: true,
        items: ref([
          {
            label: 'Escolha...',
            items: [
              {label:'Consultar',icon:'pi pi-search',tag:1, command: () => this.editarFaturamento(this.selectedItem.id,0) }
            ]
          }
        ]),
        faturamentoDto: {}
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
      this.abrirFiltroAvancado();
    },

  updated() {
      this.atualizaPage();
    },

  methods: {
    buscaFaturamentos(sv){
      if(this.aba != 1 || sv == false){return;}
      this.filter.page = this.filtros.page;
      this.filter.size = this.filtros.size;
      this.filter.sort = this.filtros.sort;

      FaturamentoService.buscarComPaginacao(this.filter)
          .then(({data}) => {
            if (!!data) {
                  this.faturamentos = data;
            }
          })
          .catch(error => {
            const toast = {
              severity: 'error',
              summary: `Falha ao buscar o faturamento.`,
              detail: error,
              life: 7500
            }

            if (!!error && !!error.response && !!error.response.data) {
              const {message} = error.response.data;

              if (!!message) {
                toast["detail"] = message.replace("produtor", "cliente");
              }
            }

            this.$toast.add(toast);
          });
    },

    abrirFiltroAvancado() {
      this.filtros = {};
      this.filtros["size"] = 10;
      this.filtros["page"] = 1;
      this.filtros["sort"] = 'id,desc';
    },

    atualizaPage(){
      if(!!this.faturamentos.pageable && !!this.faturamentos.totalPages) {
        const qtdRegistros = this.faturamentos.pageable.pageSize;
        this.totalRegistro = this.faturamentos.totalPages * qtdRegistros;
      }
    },

    modalfaturamento(){
      this.faturamentoDto = {};
      this.faturamentoDto.dataNrDocPes = new Date();
      this.modalFaturamentoDialog=true;
    },

    modalSolicitacaoEmbarquePendente(){         
      this.faturamentoDto = {};   
      this.faturamentoDto.dataNrDocPes = new Date();
      this.modalSolicitacaoEmbarquePendenteDialog=true;
    },

    fechar(save){
      this.modalSolicitacaoEmbarquePendenteDialog = false;
      this.modalFaturamentoDialog = false;
      this.buscaFaturamentos(save);
    },

    selecionarSolicitacaoEmbarquePendente(se){  
      this.modalSolicitacaoEmbarquePendenteDialog = false;      
      this.popularFaturamentoDto(se);
      this.modalFaturamentoDialog = true;
    },

    popularFaturamentoDto(se){

      if(!!se.id){
        const faturamento = {};
        faturamento.id = null;
        faturamento.dataNrDocPes = new Date();
        faturamento.solicitacaoEmbarqueId = se.id;

        faturamento.versao = 0;
        faturamento.estabelecimento = se.estabelecimento;
        faturamento.destino = se.destino;
        faturamento.terminal= se.terminal;
        faturamento.cliente = se.cliente;
        faturamento.tipoProduto = se.tipoProduto;
        faturamento.tipoPesagem = "AUTOMATICA";
        
        faturamento.grupoProduto = se.pedidos[0].grupoProduto;
        
        faturamento.estabelecimento["codigoNome"] = faturamento.estabelecimento.codigo + " - " + faturamento.estabelecimento.nome;
        faturamento.destino["label"] = faturamento.destino.nome + " / " + faturamento.destino.uf;
        if(faturamento.terminal != undefined && faturamento.terminal != null) {
          faturamento.terminal["label"] = faturamento.terminal.nomeFantasia + " / " + faturamento.terminal.cnpj;
        }
        faturamento.cliente["codigoNome"] = faturamento.cliente.codigo + " - " + faturamento.cliente.nome;
        faturamento.grupoProduto["codigoNome"] = !!faturamento.grupoProduto ? faturamento.grupoProduto.codigo + " - " + faturamento.grupoProduto.nome : null;
        faturamento.pedidos = [];
        faturamento.transportadorasAutorizadas = [];
        
        this.faturamentoDto = faturamento;
      }
    },

    editarFaturamento(id,tag){
      if (tag==0) {
        FaturamentoService.buscarPorId(id)
            .then(({data}) => {
                this.faturamentoDto = data.dto.value;
            });
        this.modalFaturamentoDialog = true;
      }
    },

    selecionar(index){
      this.split=false;
    },

    onPage(event) {
      this.filtros["page"] = event.page + 1;
      this.filtros["size"] = event.rows;
      this.buscaFaturamentos();
    },

    sort(event) {
      const sortField = event.sortField;
      const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
      this.filtros["sort"] = `${ sortField },${sortOrder}`;
    },

    excluirfaturamento(indice){
          faturamentoService.inativar(indice)
              .then(({data}) => {

                this.$toast.add({
                  severity:'success',
                  detail:`A solicitação de embarque inativada com sucesso!`,
                  life: 3000
                });
              })
              .catch(error => {
                this.$toast.add({
                  severity:'warn',
                  detail:`Falha ao inativar a solicitação de embarque!`,
                  life: 3000
                });
              });
          this.excluirFaturamentoDialog = false;
          this.buscaFaturamentos(true);

        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        }

    }
}
</script>
<script setup>
import {ref} from "vue";

const menu = ref();
const selectedItem= ref();

const toggle = (event,data,index) => {
  menu.value.toggle(event);
  selectedItem.value = data;
};

</script>
<style scoped>
    .borda-personalizada {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1rem;
    }
</style>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
